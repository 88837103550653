// LabIsFunLanding.js
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import GradientSquare from './GradientSquare';
import LabisFunLogo from '../com/images/Lab-is-Fun-Logo.svg';
import LabisFunText from '../com/images/labisfun_text.svg';

const LogoColor = '#045B62';

const BackColors = [
  'white',
  'yellow',
  '#ccffcc',
  '#99ff99',
  'white',
  '#ccffff',
  '#aaddff',
  'white',
  '#FF9BD5',
  '#D9C7EF',
];
const Degrees = [45, 225, 135, 270, 160, 315, 195, 160, 315, 225, 135, 270, 195, 45, 225, 135, 270, 160, 315, 195, 45, 225, 135, 295 ];

const size='400px';

const LIFBox = styled('div')({
    height: '100%', width: '100%',
    flexGrow: 1,
    background: 'linear-gradient(179.8deg, #045B62, white 3%)',
    textAlign: 'center',
});

export default function LabIsFunLanding() {
    const [colorFrom, setColorFrom] = useState(LogoColor);
    const [colorTo, setColorTo] = useState('white');
    const [deg, setDeg] = useState(0);
    const SupportReqAnimFrame = 'requestAnimationFrame' in window ? true : false;

    useEffect(()=>{
        let index = 0;
        let lastColor = BackColors[index];
        let degreeIdx = 0;

        const timer = setInterval(()=>{
            //console.log("timer..");
            index++;
            if(index >= BackColors.length) index = 0;
            setColorFrom(lastColor);
            const newColor = BackColors[index];
            setColorTo(newColor);
            lastColor = newColor;
            setDeg(Degrees[degreeIdx]);
            degreeIdx++;
            if(degreeIdx >= Degrees.length) degreeIdx=0;
        }, 8000);

        return () => clearInterval(timer);
    }, []);

    return(
        <LIFBox>
            <p>&nbsp;</p>
            {
                Boolean(colorFrom) && Boolean(colorTo) && SupportReqAnimFrame
                ?
                <GradientSquare
                imageUrl={LabisFunLogo}
                colorFrom={colorFrom}
                colorTo={colorTo}
                degree={deg}
                duration={3000}
                />
                :
                <div
                style={{
                    width: size,
                    height: size,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <img src={LabisFunLogo} alt="Lab is Fun, Inc." style={{ width: '80%', height: '80%', boxSizing:'border-box' }} />
                </div>
            }
            <img src={LabisFunText} alt="Lab is Fun, Inc." width={540} style={{marginTop:40}}/>
            <div style={{marginTop:20, fontSize:"1.4rem", color:LogoColor}}>
            <i>Announcing <a href="https://www.dbdbtap.com/" style={{color:'#045BA2', fontWeight:'bold'}} >DbdbTap</a>: Schema Based, Template Applied Code Generation</i>
            </div>
        </LIFBox>
    );
}